import Module from './Module';

export default class ModuleBootstrapHelper extends Module {
	constructor(el) {
		super(el);

		//-- Properties
		//--------------------------------------------------------------
		this.breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

		let b = window.document.body;
		let temp = window.document.createElement('div');

		// $.extend(this.dom, {
		//     b: window.document.body,
		//     temp: window.document.createElement("div")
		// });
	}

	//-- Methods
	//--------------------------------------------------------------
	get breakpoint() {
		let b = window.document.body;
		let temp = window.document.createElement('div');
		b.appendChild(temp);

		for (var i = this.breakpoints.length - 1; i >= 0; i--) {
			let env = this.breakpoints[i];

			temp.className = 'hidden-' + env;

			if (temp.hidden === true) {
				temp.parentNode.removeChild(temp);
				return env;
			}
		}
		return '';
	}
}
