var Modules = {
    Module: require("./modules/Module")
};

window.Modules = Modules;

var Views = {
    Home: require("./views/ViewHome")
};

// window.onload = function() {
//     initComponents(document.querySelector("#site"));
// }

document.addEventListener("DOMContentLoaded", function(event) {
    initComponents(document.querySelector("#site"));
});

window.initComponents = function (context) {
    if (typeof window.initClasses === "undefined") { 
        Array.prototype.forEach.call(context.querySelectorAll("[data-view]"), function(el) {
            let list = el.dataset.view.split(/\s+/);
            list.forEach( function (name) {
                if (Views[name] !== undefined) {
                    new Views[name].default(el).init();
                }
            });
        });
    }

    Array.prototype.forEach.call(context.querySelectorAll("[data-component]"), function(el) {
        let list = el.dataset.component.split(/\s+/);
        list.forEach( function (name) {
            if (window.Modules[name] !== undefined) {
                let m = new window.Modules[name].default(el);
                m.init();
            }
        });
    });
};