import View from "./View";

export default class ViewHome extends View {
    constructor(el) {
        super(el);
    }

    init() {
        super.init();
    }
}
