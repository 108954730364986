import ua from '../../../../../node_modules/ua-parser-js';
import {
	setModuleBg,
	setMissingBackgroundInObject,
} from '../helpers/responsiveBg';
import ModuleBootstrapHelper from '../modules/ModuleBootstrapHelper';
import Debounce from '../helpers/debounce';

export default class View {
	constructor(el) {
		this.dom = {
			el: el,
			body: document.querySelector('body'),
			html: document.querySelector('html'),
			site: document.querySelector('#site'),
			w: window,
		};

		this.ua = new ua().getResult();
	}

	init() {
		this.initBootstrapHelper();
		this.setDeviceSize();
		this.setDeviceType();
		setMissingBackgroundInObject();
		setModuleBg(this.breakpoint);

		window.ua = this.ua;

		window.addEventListener(
			'resize',
			Debounce(() => {
				this.setDeviceSize();
				this.breakpoint = this.bootstrapHelper.breakpoint;
				window.breakpoint = this.bootstrapHelper.breakpoint;
				setModuleBg(this.breakpoint);
			}, 200)
		);
	}

	initBootstrapHelper() {
		this.bootstrapHelper = new ModuleBootstrapHelper();
		this.breakpoint = this.bootstrapHelper.breakpoint;
		window.breakpoint = this.bootstrapHelper.breakpoint;
	}

	setDeviceSize() {
		// IE FIX FOR getComputedStyle
		if (!window.getComputedStyle) {
			window.getComputedStyle = function (el) {
				this.el = el;
				this.getPropertyValue = function (prop) {
					var re = /(\-([a-z]){1})/g;
					if (prop == 'float') prop = 'styleFloat';
					if (re.test(prop)) {
						prop = prop.replace(re, function () {
							return arguments[2].toUpperCase();
						});
					}
					return el.currentStyle[prop] ? el.currentStyle[prop] : null;
				};
				return this;
			};
		}

		var newDevice = window
			.getComputedStyle(document.body, ':after')
			.getPropertyValue('content');

		// IE8 DEFAULT VALUE
		if (!newDevice) {
			newDevice = 'xl';
		}

		// IE9-10 REMOVE QUOTE FROM CONTENT STRING
		newDevice = newDevice.replace(/["']/g, '');

		if (newDevice != window.deviceSize) {
			window.deviceSize = newDevice;
			// $(document).trigger("newDeviceSize");
			var event = document.createEvent('HTMLEvents');
			event.initEvent('change', true, false);
			document.dispatchEvent(event);
		}
	}

	setDeviceType() {
		let OS = this.ua.os.name.split(' ').join('_');
		let browser = this.ua.browser.name.split(' ').join('_');

		this.dom.html.classList.add(OS);
		this.dom.html.classList.add(browser);
	}
}
